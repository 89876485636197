.add-session-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.add-session-body-container{
    margin-top: 80px;
}

.add-session-header-div {
    display: flex;
    justify-content: center;
}

.label-add {
    font-size: 1.5rem;
    margin-bottom: 10px;
}
