body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
}

.logout-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
}

h1 {
    color: #333;
}

p {
    color: #555;
}

.login-button {
    display: inline-block;
    background-color: #57a8cc;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    border: none;
    text-decoration: none;
}

.login-button:hover {
    background-color: #57a8cb;
}

/* Mobile Styles */
@media (max-width: 600px) {
    body {
        padding: 10px;
    }

    .logout-container {
        padding: 15px;
    }

    .login-button {
        padding: 10px 15px;
        font-size: 14px;
    }
}