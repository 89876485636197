.add-municipality-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.add-municipality-body-container{
    margin-top: 80px;
}
