.change-password-container {
       display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.password-change-back-button {
    background-color: #57a8cb;
    padding: 10px 20px;
    font-size: 16px;
    border: 0px solid white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.change-password-button-container {
    min-width: 180px;
}

.password-change-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}


/* Style for the success message */
.success-message {
    color: #28a745;
    /* Green color for success */
    background-color: #d4edda;
    /* Light green background */
    border: 1px solid #c3e6cb;
    /* Border to match the theme */
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    font-weight: bold;
}

/* Style for the error message */
.error-message {
    color: #dc3545;
    /* Red color for error */
    background-color: #f8d7da;
    /* Light red background */
    border: 1px solid #f5c6cb;
    /* Border to match the theme */
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    font-weight: bold;
}




