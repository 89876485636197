.admin-panel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.user-back-button {
    width: 180px;
    background-color: #57a8cb;
    padding: 10px 20px;
    font-size: 16px;
    border: 0 solid white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    margin-top: 5px;
    margin-right: 30px;
}

.user-add-button {
    width: 180px;
    background-color: #57a8cb;
    padding: 10px 20px;
    font-size: 16px;
    border: 0 solid white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    margin-top: 10px;
    margin-left: 30px;
}

.user-add-button:hover {
    background-color: #57a8cc;
}

.admin-title {
    margin: 10px 20px;
}
.admin-body {
    flex: 1;
    margin-top: 90px;
    padding: 20px;
    width: 80vw;
}

.admin-user-lists {
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f8f9fa;
    padding: 10px;
}

.custom-table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fafafa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table-responsive table {
    width: 100%;
    table-layout: auto;
}

.user-admin-img {
    width: 50px;
    height: 70px;
    object-fit: cover;
}

@media (max-width: 1240px) {
    .admin-body{
        width: 100vw;
    }
   
}

@media (max-width: 1024px) {
    .user-add-button,
    .user-back-button {
        font-size: 14px;
    }
}

@media (max-width: 1024px) {

    .custom-table-responsive table th,
        .custom-table-responsive table td {
            padding: 8px;
            width: calc(100% / 13);
            white-space: nowrap;
            text-align: center;
        }
}

@media (max-width: 768px) {

    .custom-table-responsive table thead tr th,
    .custom-table-responsive table tr td,
    .custom-table-responsive table tr td form button {
        font-size: 14px;
    }

    
    .a-tag-user-back-button,
    .user-back-button{
        display: none;
    }

    .user-add-button {
        margin-left: 10px;
        margin-right: 50px;
    }
}

@media (max-width: 600px) {

    .admin-title{
        font-size: 25px;
    }

    .user-add-button {
        margin-right: 30px;
    }
}

@media (max-width: 500px) {
    .user-add-button {
        margin-right: 10px;
        font-size: 12px;
        width: 150px;
    }
}

@media (max-width: 460px) {

    .admin-title{
        font-size: 23px;
    }
    .user-add-button {
        font-size: 11px;
        width: 140px;
    }
}

@media (max-width: 420px) {

    .admin-title {
        font-size: 21px;
    }

    .user-add-button {
        font-size: 10px;
        width: 130px;
    }
}

@media (max-width: 400px) {

    .admin-title {
        font-size: 19px;
    }

    .user-add-button {
        font-size: 10px;
        width: 130px;
        padding: 7px 17px;
    }
}

@media (max-width: 380px) {

    .admin-title {
            font-size: 17px;
        }
    .user-add-button {
        font-size: 10px;
        padding: 5px 5px;
        margin-left: 2px;
        width: 120px;
    }
}


@media (max-width: 380px) {

    .admin-title {
        font-size: 16px;
    }

    .user-add-button {
        font-size: 9px;
        padding: 5px 3px;
        width: 100px;
    }
}

