.add-user-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.add-user-form-body{
    margin-top: 50px;
    padding: 10px;
}

.success-message {
    color: #28a745;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    font-weight: bold;
}

.error-message {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    font-weight: bold;
}
.text-info-image-input,
.image-add-input{
    width: 500px;
}

.eye-icon{
    margin-left: -30px;
    cursor: pointer;
    margin-top: 15px;
    font-size: 1.25rem;
    background-color: white;
}

@media (max-width: 600px) {
    .text-info-image-input,
    .image-add-input{
        width: 80vw;
    }
}

@media (max-width: 440px) {

    .action-buttons {
        font-size: 17px !important;
    }

    .text-info-image-input,
    .image-add-input {
        width: 70vw;
    }
}