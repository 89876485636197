.change-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back-button-image {
    margin-right: 10px;
}

.image-form {
    width: 600px;
    max-width: 600px;
    margin: 0 auto;
}

.file-drop-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    padding: 25px;
    border: 2px dashed #007bff;
    border-radius: .25rem;
    transition: border-color 0.3s;
    cursor: pointer;
    text-align: center;
}


.file-drop-area.is-active {
    border-color: #0056b3;
}

.file-drop-message {
    margin: 0;
    font-size: 16px;
    color: #6c757d;
    overflow: hidden;
    text-overflow: ellipsis;
}



.file-drop-area input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.file-drop-message span {
    color: #007bff;
    font-weight: 600;
}

.parent-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    /* Optional: for full-height parent div */
    margin-top: -50px;
}

.back-button-container {
    margin-top: -250px;
    margin-right: -200px;
}

.error-message-image {
    display: flex;
    justify-content: center;
    background-color: rgba(217, 83, 79, 0.4);
    border-radius: 5px;
    text-align: center;
    color: #d9534f;
}



/* Media Query for Mobile Screens */
@media (max-width: 620px) {
    .image-form {
        width: 550px;
        max-width: none;
    }
}

@media (max-width: 570px) {
    .image-form {
        width: 500px;
    }
}

@media (max-width: 520px) {
    .image-form {
        width: 450px;
    }
}

@media (max-width: 460px) {
    .image-form {
        width: 400px;
    }
}

@media (max-width: 410px) {
    .image-form {
        width: 350px;
    }
}

@media (max-width: 360px) {
    .image-form {
        width: 300px;
    }
}

