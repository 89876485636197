.back-button-presenter {
    background-color: #57a8cb;
    padding: 10px 20px;
    font-size: 16px;
    border: 0px solid white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s;
    margin-left: 20px;
    margin-top: 10px;
    opacity: 0.3;
}

.back-button-presenter:hover {
    opacity: 1;
}

.logo-img-presenter {
    width: 50px;
    object-fit: cover;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 5px;
}