body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
}

.login-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 440px;
    box-sizing: border-box;
}

h1 {
    color: #333;
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 5px;
}

input[type="submit"] {
    background-color: #57a8cc;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

input[type="submit"]:hover {
    background-color: #57a8cb;
}

.error-message {
    color: #d9534f;
    margin-bottom: 15px;
}